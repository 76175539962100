<template>
  <div class="offers-dialog position-relative">
    <img
      width="10px"
      class="cursor-pointer close-btn"
      :src="require('@/assets/images/icons/close.svg')"
      @click="$emit('close')"
    />

    <router-link
      v-if="!noPreview"
      class="w-100"
      :to="`/offers/${profile.numericId}`"
      target="_blank"
    >
      <v-btn block elevation="2">Preview</v-btn></router-link
    >
    <v-btn block elevation="2" class="mt-2" @click="copyLink">Copy Link</v-btn>
    <v-btn v-if="!noQrCode" block elevation="2" class="mt-2" @click="qrCodeDialog = true"
      >QR Code</v-btn
    >

    <v-dialog
      v-model="qrCodeDialog"
      content-class="white position-relative"
      max-width="450px"
    >
      <img
        width="10px"
        class="cursor-pointer close-btn"
        :src="require('@/assets/images/icons/close.svg')"
        @click="qrCodeDialog = false"
      />

      <vue-html2pdf
        :show-layout="false"
        :float-layout="true"
        :enable-download="true"
        :preview-modal="false"
        :paginate-elements-by-height="1400"
        filename="offers-qr-code"
        :pdf-quality="2"
        :manual-pagination="false"
        pdf-format="a4"
        pdf-orientation="landscape"
        pdf-content-width="100%"
        ref="html2Pdf"
      >
        <section slot="pdf-content" class="text-center">
          <share-offers-pdf :url="offersPageUrl" />
        </section>
      </vue-html2pdf>

      <share-offers-pdf :url="offersPageUrl" :enable-edit="true" />

      <v-btn
        color="primary"
        elevation="2"
        class="mt-2 download-pdf-btn"
        @click="generatePdf"
        >Download QR Code</v-btn
      >
    </v-dialog>
  </div>
</template>

<script>
import VueHtml2pdf from "vue-html2pdf";
import ShareOffersPdf from "./ShareOffersPdf.vue";

export default {
  components: {
    VueHtml2pdf,
    ShareOffersPdf,
  },
  props: {
    noPreview: {
      type: Boolean,
      default: false,
    },
    noQrCode: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      qrCodeDialog: false,
    };
  },
  computed: {
    offersPageUrl() {
      return `${window.location.origin}/offers/${this.profile.numericId}`;
    },
  },
  methods: {
    copyLink() {
      navigator.clipboard.writeText(this.offersPageUrl);

      this.$store.dispatch("showSnack", {
        text: "Copied to clipboard",
        color: "success",
      });
    },
    generatePdf() {
      this.$refs.html2Pdf.generatePdf();
    },
  },
};
</script>

<style lang="scss">
.download-pdf-btn {
  border-radius: 8px;
  width: calc(100% - 40px) !important;
  margin-inline: 20px;
  margin-bottom: 20px;
}

.close-btn {
  position: absolute;
  right: 20px;
  top: 20px;
  width: 10px;
}
</style>
