<template>
  <div
    class="share-offers-pdf d-flex flex-column align-center"
    style="
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 50px auto;
    "
  >
    <div
      class="details d-flex flex-column align-center"
      style="
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 60px;
      "
    >
      <img
        class="logo"
        :src="
          profile.logo
            ? profile.logo.url
            : require('@/assets/images/icons/img-placeholder.svg')
        "
        alt="Logo"
      />

      <div class="slogan font-18 font-600 px-5">
        <span v-if="!showSloganInput" @click="toggleShowSloganInput">{{
          profile.slogan
        }}</span>
        <input
          v-else
          ref="sloganInput"
          class="bg-white w-100"
          type="text"
          v-model="slogan"
          @keyup.enter="$event.target.blur()"
          @blur="toggleShowSloganInput"
        />
      </div>
    </div>

    <div class="qrcode-container border-rounded pa-2">
      <canvas id="qrcode_canvas" ref="qrcodeCanvas"></canvas>
    </div>
  </div>
</template>

<script>
import qrcode from "qrcode";

export default {
  props: {
    url: {
      type: String,
      required: true,
    },
    enableEdit: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      slogan: "",
      showSloganInput: false,
    };
  },
  mounted() {
    this.slogan = this.profile.slogan;

    let canvas = this.$refs.qrcodeCanvas;

    qrcode.toCanvas(canvas, this.url, {
      color: {
        light: "#FFFFFF",
        dark: "#000000",
      },
      width: 140,
      margin: 2,
    });
  },
  methods: {
    toggleShowSloganInput() {
      if (!this.enableEdit) {
        return;
      }

      // validate slogan
      if (!this.slogan.length) {
        this.$store.dispatch("showSnack", {
          text: "Slogan can't be empty",
          color: "error",
        });

        this.slogan = this.profile.slogan;
        this.showSloganInput = !this.showSloganInput;

        return;
      }

      if (this.showSloganInput) {
        let payload = new FormData();
        payload.append("slogan", this.slogan);
        this.$axios
          .patch(process.env.VUE_APP_PROFILE, payload)
          .then((res) => {
            if (res.data) {
              this.$store.dispatch("setProfile");
            }
          })
          .catch((err) => {
            this.$store.dispatch("showSnack", {
              text: err.error || err.message || "Something went wrong",
              color: "error",
            });
          });

        this.showSloganInput = false;
      } else {
        this.showSloganInput = !this.showSloganInput;

        this.$nextTick(() => this.$refs.sloganInput.focus());
      }
    },
  },
  watch: {
    profile: {
      deep: true,
      handler(newVal) {
        console.log("newVal: ", newVal);
      },
    },
  },
};
</script>

<style lang="scss">
.share-offers-pdf {
  width: fit-content;
  background-color: #ffffff;
  border-radius: 8px;
  padding: 20px;

  .details {
    position: relative;
    border-radius: 9.825px;
    border: 1px solid #d6d6d6;
    background: #efefef;

    img.logo {
      width: 92px;
      height: 92px;
      border-radius: 50%;
      box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
      position: absolute;
      top: 0;
      left: 50%;
      transform: translate(-50%, -75%);
    }

    .slogan {
      max-width: 350px;
      min-width: 250px;
      text-align: center;
      padding: 9px;
      padding-top: 35px;
    }

    input {
      background-color: white;
      // box-shadow: 0px 0px 1px rgba(0, 0, 0);
    }
  }

  .qrcode-container {
    position: relative;
    margin-top: 80px;

    &::before {
      content: "";
      position: absolute;
      top: -8px;
      left: 50%;
      transform: translate(-50%, -80%);
      background-image: url("../../../assets/images/icons/hand-holding-mobile.png");
      background-repeat: no-repeat;
      background-size: contain;
      width: 60px;
      height: 60px;
    }

    #qrcode_canvas {
      border-radius: 9.609px;
      border: 2px solid #000;
    }
  }
}
</style>
