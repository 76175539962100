<template>
  <div class="offer-card pa-0 d-flex">
    <div class="offer-card__image">
      <img
        v-if="product.item.show === 'image' && product.item.images.length === 1"
        class="offer-card__img"
        :src="
          product.item.images[0].url ||
          require('@/assets/images/icons/item-placeholder.svg')
        "
        :draggable="false"
      />

      <img
        v-else-if="product.item.show === 'youtube'"
        class="offer-card__img"
        :src="extractYoutubeThumbnailUrl(product.item.links[0].url)"
      />

      <img
        v-else-if="product.item.show === 'video'"
        class="offer-card__img"
        :src="product.item.video.thumbnail"
      />
    </div>

    <div
      class="offer-card__content d-flex flex-column justify-between"
      v-if="hasSomeContent(product, false)"
    >
      <p
        class="offer-card__banner font-12 font-700 w-fit-content ma-0"
        :title="product.item.banner.text"
        v-if="product.item.banner && product.item.banner.text"
      >
        {{ product.item.banner.text }}
      </p>
      <div class="offer-card__text pr-2">
        <div
          class="offer-card__title font-16 font-600"
          v-if="product.item.title"
          ref="titles"
        >
          <span>{{ product.item.title }}</span>
        </div>
        <div
          class="offer-card__description font-11"
          v-if="product.item.description"
          ref="descriptions"
          :title="product.item.description"
        >
          {{ product.item.description }}
        </div>
      </div>
      <div class="offer-card__prices pa-2">
        <span class="offer-card__price" ref="prices" v-if="product.item.price">
          <span class="font-17 font-900">{{ product.item.price }}</span
          ><span class="currency font-11" ref="currency">{{ currency }}</span>
        </span>
        <span class="discount" v-if="product.item.oldPrice" ref="discounds"
          ><span class="font-12 font-400">{{ product.item.oldPrice }}</span
          ><span class="currency font-8" ref="discount_currency">{{
            currency
          }}</span>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    product: {
      default: Object,
      required: true,
    },
    passedCurrency: {
      required: false,
      default: "",
    },
  },
  computed: {
    currency() {
      return this.profile.currency
        ? this.profile.currency.symbol
        : this.passedCurrency;
    },
  },
};
</script>

<style lang="scss" scoped>
.offer-card {
  height: 132px;
  background: #f8f8f8;
  border-radius: 10px;
  // overflow: hidden;
  // box-shadow: 2px 2px 10px 5px #0000002f;

  .offer-card__image {
    aspect-ratio: 1 / 1;
    // width: 132px;
    // height: 100%;
    position: relative;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
    border-radius: 10px;

    img {
      width: 100%;
      height: 100%;
      border-radius: 10px;
      object-fit: cover;
    }
  }

  .offer-card__banner {
    min-width: 25px;
    text-align: center;
    background-color: #fdd039;
    color: black;
    padding: 4px;
    border-radius: 6px;
  }

  .offer-card__content {
    width: calc(100% - 132px);
    padding-top: 8px;
    padding-left: 8px;
    word-wrap: break-word;
    justify-content: space-between;

    .offer-card__text {
      color: rgba(0, 0, 0, 1);

      .offer-card__title,
      .offer-card__description {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: calc(100% - 15px);
      }
    }
    .offer-card__prices {
      width: fit-content;
      min-width: 85px;
      background: #fdd039;
      color: #000;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      border-radius: 10px;
      box-shadow: -2.16024px 0px 1.29615px -0.43205px rgba(0, 0, 0, 0.25);
      margin-left: auto;

      span {
        line-height: 1;
      }
    }
    .offer-card__price {
      font-weight: bold;
    }
  }
}
</style>
